import React, { ReactNode, useMemo } from 'react'
import { ProductBlock } from '@heights/heights-ui-components'
import { BLOCKS } from '@contentful/rich-text-types'
import { Text } from '@theme-ui/components'
import { SectionFeatureFlags } from '@/contentful/utils/commonValues'
import { withErrorBoundary } from '@/utils'
import { useCtaAction } from '@/hooks/useCtaAction'
import { RichTextContent } from '@/components/RichTextContent'
import compact from 'just-compact'
import { Box } from 'theme-ui'
import { transformImage } from '@/utils/contentful'
import { useGlobalData, sectionDataHookFactory } from '@/hooks'
import { textItem as textItemSchema } from '@/schemas/textItem'
import { ctaAction as ctaActionSchema } from '@/schemas/ctaAction'
import type { SingleComponentPickerItem } from '../../SingleComponentPicker'
import { IconNames } from '@heights/heights-icons'
import { asResolverQuery } from '@/utils/groq'

type Props = {
  variant: 'intermezzo' | 'inline'
  data: SingleComponentPickerItem
}

const [textItemQuery, textItemType] = textItemSchema.builder.use()

const [ctaActionQuery, ctaActionType] = ctaActionSchema.builder.use()

const useSectionData = sectionDataHookFactory({
  textItems: asResolverQuery<typeof textItemType>(
    'fields.components',
    textItemQuery,
    '@->_type == "textItem"'
  ),
  ctaActions: asResolverQuery<typeof ctaActionType>(
    'fields.components',
    ctaActionQuery,
    '@->_type == "ctaAction"'
  ),
})

export const ContentfulSectionProductBlock: React.FunctionComponent<Props> =
  withErrorBoundary(({ variant, data }) => {
    const globalData = useGlobalData()
    const section = useSectionData(data._id)

    const ctaActionComponents = compact([
      section?.ctaAction,
      ...(section?.ctaActions?.map((entry) => entry?.fields) ?? []),
    ])
    const callToActions = useCtaAction(ctaActionComponents)

    const reverseOrder = useMemo(() => {
      return !!section?.fields?.enabledFeatureFlags?.includes(
        SectionFeatureFlags.PRODUCT_BLOCK__REVERSE_ORDER
      )
    }, [section?.fields?.enabledFeatureFlags])

    if (!section) {
      return null
    }

    return (
      <Box
        sx={
          variant === 'intermezzo'
            ? { mx: [null, null, -11, '-13rem'] }
            : { mx: [] }
        }
      >
        <ProductBlock
          variant={variant}
          reverseOrder={reverseOrder}
          title={section.fields?.title}
          backgroundColor={section.fields?.backgroundColor ?? undefined}
          description={
            section.fields?.description && (
              <RichTextContent
                document={section.fields?.description}
                renderNodeOverrides={{
                  [BLOCKS.PARAGRAPH]: (_, children): ReactNode => (
                    <div>{children}</div>
                  ),
                }}
              />
            )
          }
          subtitle={section.fields?.subtitle}
          image={
            transformImage(section.backgroundImage?.fields, {
              width: 1000,
              objectFit: 'cover',
              objectPosition: 'center',
              ...(variant === 'intermezzo'
                ? {
                    fullscreen: true,
                    sizes: '50vw',
                  }
                : {}),
            }) ?? undefined
          }
          mobileImage={
            transformImage(section.mobileBackgroundImage?.fields, {
              width: 1000,
              objectFit: 'cover',
              objectPosition: 'center',
              ...(variant === 'intermezzo'
                ? {
                    fullscreen: true,
                    sizes: '100vw',
                  }
                : {}),
            }) ?? undefined
          }
          benefitsTitle={
            section?.fields?.label ??
            (variant === 'intermezzo' ? undefined : 'Benefits')
          }
          benefits={compact(
            section?.textItems?.map((textItem) =>
              textItem?.fields?.title && textItem?.fields.icon
                ? {
                    label: textItem.fields?.title,
                    icon: textItem.fields?.icon as IconNames,
                  }
                : null
            ) ?? []
          )}
          starReview={
            variant === 'intermezzo'
              ? {
                  rating: globalData?.fields?.trustpilotScore,
                }
              : undefined
          }
          cta={
            variant === 'intermezzo'
              ? callToActions?.length
                ? callToActions.map((action) => ({
                    style: 'primary',
                    ...action,
                  }))
                : {
                    type: 'button',
                    style: 'primary',
                    href: '/products/the-smart-supplement/',
                    label: 'Shop now',
                  }
              : callToActions?.map((action) => ({
                  style: 'tertiary',
                  ...action,
                }))
          }
        />
      </Box>
    )
  })

export default ContentfulSectionProductBlock
